import React from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import Heading from '../../components/heading/heading';


class Hero extends React.Component {
  render() {
    const { t } = this.props;
    return (

      <Container fluid className="masthead">
          <p>{t('home.lead')}</p>
          <Heading as="h1" type="display">{t('home.heading')}</Heading>
      </Container>



    )
  }
}

export default withTranslation()(Hero);