import React from 'react';
import LayoutContainerLess from '../layout/layoutContainerLess';
import Seo from '../layout/seo';
import Home from '../content/home/home';

class Index extends React.Component {
  render() {
    return (
      <LayoutContainerLess location={this.props.location}>
        <Seo lang="en" title="Sports Nutrition Plans" location={this.props.location} />
      <Home />
      </LayoutContainerLess>
    )
  }
}

export default Index;