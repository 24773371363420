/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from './footer';
//import "semantic-ui-less/semantic.less";
import {withTranslation} from 'react-i18next';
//import 'semantic-ui-css/semantic.css'
/**
 * Here we have to look at the path, and then check if it matches the language. If it doesn't,
 * we need to redirect to the new location.
 * @param {
 * } param0 
 */


const LayoutContainerLess = ({ location, variant, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryHome {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} location={location}  variant={variant}/>
    { /* 
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            margin-top: 100px
          }}
        >
          */}
          <main role="main" className="flex-shrink-0 flex-grow-1">
          {children}
          </main>
          <Footer />
        
        {/* </div> */ }
      </>
    )}
  />
)

LayoutContainerLess.propTypes = {
  children: PropTypes.node.isRequired,
}
LayoutContainerLess.defaultProps = {
  variant: 'light'
}

export default withTranslation()(LayoutContainerLess);
